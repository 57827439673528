import { ProfileService } from '@/services/profile.service';
import type { Profile } from '@prisma/client';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Language } from '~/enum/language.enum';

export const useProfileStore = defineStore('profile', () => {
  const profiles = ref<Profile[]>([]);
  const companyProfiles = ref<Profile[]>([]);

  async function createProfile(
    url: string,
    isBaseCompany: boolean,
    recaptchaToken: string,
    language: Language,
    signal?: AbortSignal
  ) {
    const res: Profile = await ProfileService.create(
      url,
      !isBaseCompany,
      recaptchaToken,
      language,
      signal
    );
    if (isBaseCompany) {
      profiles.value.push(res);
    }
    return res;
  }

  async function postProfile(
    profile: Profile,
    isBaseCompany: boolean,
    language: Language
  ) {
    const res: Profile = await ProfileService.post(
      profile,
      !isBaseCompany,
      language
    );
    if (isBaseCompany) {
      profiles.value.push(res);
    }
    companyProfiles.value.push(res);
    return res;
  }

  function clear() {
    profiles.value = [];
    companyProfiles.value = [];
  }

  return {
    clear,
    profiles,
    createProfile,
    postProfile,
  };
});
