import type { Profile } from '@prisma/client';
import type { Language } from '~/enum/language.enum';
import readStream from '~/utils/readStream';

export const OpenAiService = {
  async checkCompatibility(
    baseCompany: Profile,
    targetCompany: Profile,
    recaptchaToken: string,
    language: Language
  ): Promise<{ isCompatible: boolean; reason: string }> {
    try {
      const res = await $fetch('/api/check/compatibility', {
        body: JSON.stringify({
          baseCompany,
          targetCompany,
          language,
        }),
        method: 'POST',
        headers: { 'X-Recaptcha-Token': recaptchaToken },
        retry: false,
      });
      return res;
    } catch (e) {
      console.error(e);
      throw new Error(`Error while checking compatibility`);
    }
  },

  async generateTextsAsStreams(
    apiUrl: 'salestext' | 'salestext-product' | 'what-why' | 'what-why-product',
    language: Language,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
    recaptchaToken: string,
    valCb?: (val: string) => void
  ) {
    const stream = await fetch(`/api/generators/landing-${apiUrl}`, {
      body: JSON.stringify({ ...payload, language }),
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': recaptchaToken,
      },
      method: 'POST',
    });
    if (!stream.ok) {
      throw { status: stream.status, statusMessage: stream.statusText };
    }

    await readStream(stream, undefined, valCb);
  },
};
