import { ResultTextType } from '@/enum/result-text-type.enum';
import type { CompanyProfile } from '@/interfaces/company-profile.interface';
import { OpenAiService } from '@/services/openai.service';
import { useCompanyStore } from '@/stores/company.store';
import { useProfileStore } from '@/stores/profile.store';
import { useScanStore } from '@/stores/scan.store';
import type { DetailedProduct, Profile, Scan } from '@prisma/client';
import EventEmitter from 'events';
import { reactive, ref } from 'vue';
import type { IReCaptchaComposition } from 'vue-recaptcha-v3';
import { ErrorCode } from '~/enum/error-code.enum';
import type { Language } from '~/enum/language.enum';
import type { SalesTextModificator } from '~/enum/sales-text-modificator.enum';

interface ResultTexts {
  compatibilityReason: Ref<string>;
  salesArgumentation: Ref<string>;
  salesText: Ref<string>;
  salesTextProduct: Ref<string>;
  whatWhy: Ref<string>;
  whatWhyProduct: Ref<string>;
}

interface ActiveScan {
  id?: string;
  scan?: Scan;
  companyProfile?: Partial<Profile>;
  targetProfile?: Partial<Profile>;
  detailedProduct?: Partial<DetailedProduct>;
  resultTexts: ResultTexts;
}

export const useScan = () => {
  const companyStore = useCompanyStore();
  const profileStore = useProfileStore();
  const scanStore = useScanStore();

  const { baseCompany, targetCompany, activeScan } = storeToRefs(companyStore);

  const emitter = new EventEmitter();
  const currentScan = reactive<ActiveScan>({
    resultTexts: {
      compatibilityReason: ref(''),
      salesArgumentation: ref(''),
      salesText: ref(''),
      salesTextProduct: ref(''),
      whatWhy: ref(''),
      whatWhyProduct: ref(''),
    } as ResultTexts,
  });

  function clear() {
    currentScan.resultTexts.compatibilityReason = '';
    currentScan.resultTexts.salesArgumentation = '';
    currentScan.resultTexts.salesText = '';
    currentScan.resultTexts.salesTextProduct = '';
    currentScan.resultTexts.whatWhy = '';
    currentScan.resultTexts.whatWhyProduct = '';
  }

  function setCompanyProfile(profile: CompanyProfile | undefined) {
    currentScan.companyProfile = profile;
    currentScan.detailedProduct = undefined;
  }

  function setCompanyUrl(url: string) {
    currentScan.companyProfile = { url };
    currentScan.detailedProduct = undefined;
  }

  function setTargetUrl(url: string) {
    currentScan.targetProfile = { url };
  }

  function setTargetProfile(profile: CompanyProfile | undefined) {
    currentScan.targetProfile = profile;
  }

  function setDetailedProduct(product: DetailedProduct | undefined) {
    currentScan.detailedProduct = product;
  }

  async function createCompanyProfile(
    recaptchaToken: string,
    language: Language
  ) {
    emitter.emit('scan:profile:start', { isBaseProfile: true });
    try {
      const profile = await profileStore.createProfile(
        currentScan.companyProfile?.url ?? '',
        true,
        recaptchaToken,
        language
      );
      scanStore.setBase(profile);
      emitter.emit('scan:profile:finish', {
        isBaseProfile: true,
        profile,
      });
      currentScan.companyProfile = profile;
    } catch (e) {
      emitter.emit(
        'scan:profile:error',
        {
          isBaseProfile: true,
        },
        e
      );
      throw e;
    }
  }

  async function createTargetProfile(
    recaptchaToken: string,
    language: Language
  ) {
    emitter.emit('scan:profile:start', { isBaseProfile: false });
    try {
      const profile = await profileStore.createProfile(
        currentScan.targetProfile?.url ?? '',
        false,
        recaptchaToken,
        language
      );
      scanStore.setTarget(profile);
      emitter.emit('scan:profile:finish', {
        isBaseProfile: false,
        profile,
      });
      currentScan.targetProfile = profile;
    } catch (e) {
      emitter.emit('scan:profile:error', { isBaseProfile: false }, e);
      throw e;
    }
  }

  async function matchProfiles(
    recaptchaInstance: IReCaptchaComposition,
    language: Language
  ) {
    if (!currentScan.companyProfile?.url) {
      return emitter.emit('scan:error', 'Profil-Url fehlt');
    }
    if (!currentScan.targetProfile?.url) {
      return emitter.emit('scan:error', 'Zielprofil-Url fehlt');
    }

    trackAtServer('scan_landing', {
      ownLink: currentScan.companyProfile.url,
      targetLink: currentScan.targetProfile.url,
    });

    const promises = [];

    if (!currentScan.companyProfile?.id) {
      const tokenCompany = await recaptchaInstance.executeRecaptcha(
        'createCompanyProfile'
      );
      promises.push(createCompanyProfile(tokenCompany ?? '', language));
    }

    if (!currentScan.targetProfile?.id) {
      const tokenTarget = await recaptchaInstance.executeRecaptcha(
        'createTargetProfile'
      );
      promises.push(createTargetProfile(tokenTarget ?? '', language));
    }

    const loadingText = `${
      promises.length === 1 ? 'Profil wird' : 'Profile werden'
    } erstellt`;

    emitter.emit('scan:start', loadingText);

    await Promise.all(promises);

    emitter.emit('scan:compatibility');
    const tokenCompat = await recaptchaInstance.executeRecaptcha(
      'checkCompatibilityLanding'
    );
    const compatilibityReason = await checkCompatibilityLanding(
      tokenCompat ?? '',
      language
    );
    if (!compatilibityReason) {
      return emitter.emit('scan:compatibility:fail');
    }

    emitter.emit('scan:salestext:start');
    const tokenWhatWhy = await recaptchaInstance.executeRecaptcha(
      'genereateWhatsWhyLanding'
    );
    await generateWhatsWhyLanding(tokenWhatWhy ?? '', language);
    emitter.emit('scan:salestext:finish');

    emitter.emit('scan:letter:start');
    const tokenSales = await recaptchaInstance.executeRecaptcha(
      'generateSalesTextLanding'
    );
    await generateSalesTextLanding(tokenSales ?? '', language);
    emitter.emit('scan:letter:finish');

    emitter.emit('scan:complete');

    trackAtServer('scan_landing_complete');
  }

  async function generateSalesTextLanding(
    recaptchaToken: string,
    language: Language
  ) {
    let text = '';

    const callback = (val: string) => {
      text += val;
      useScanStore().setSalesText(text);
    };

    await OpenAiService.generateTextsAsStreams(
      'salestext',
      language,
      {
        targetProfile: currentScan.targetProfile,
        baseProfile: currentScan.companyProfile,
        whatWhy: useScanStore().activeScan.resultTexts.whatWhy,
      },
      recaptchaToken,
      callback
    );
  }

  async function generateWhatsWhyLanding(
    recaptchaToken: string,
    language: Language
  ) {
    let text = '';

    const callback = (val: string) => {
      text += val;
      useScanStore().setWhatsWhy(text);
    };

    await OpenAiService.generateTextsAsStreams(
      'what-why',
      language,
      {
        targetProfile: currentScan.targetProfile,
        baseProfile: currentScan.companyProfile,
      },
      recaptchaToken,

      callback
    );
  }

  async function checkActiveScan(
    isProductScan: boolean,
    recaptchaToken: string,
    language: Language
  ) {
    if (!activeScan.value) {
      const companyProfileId = isProductScan
        ? undefined
        : baseCompany.value?.id;
      const detailedProductId = isProductScan
        ? companyStore.baseProduct?.id
        : undefined;
      const targetProfileId = companyStore.targetCompany?.id;

      if(!targetProfileId){
        throw createError({
          statusCode: 400,
          statusMessage: ErrorCode.GENERIC,
        })
      }

      await companyStore.createScan({
        companyProfileId,
        detailedProductId,
        targetProfileId ,
        recaptchaToken,
        language,
      });
    }
  }

  async function generateSalesText(
    isProductScan = false,
    recaptchaToken: string,
    signal: AbortSignal,
    language: Language
  ) {
    await checkActiveScan(isProductScan, recaptchaToken, language);
    const api = isProductScan ? 'salestext-product' : 'salestext';
    await companyStore.generateTextsAsStreams(
      api,
      recaptchaToken,
      signal,
      language
    );
    await companyStore.createResultText({
      name: 'Salestext',
      resultTextType: ResultTextType.SALESTEXT,
      scanId: activeScan.value?.id ?? '',
      text: isProductScan
        ? companyStore.generatedTexts.salesTextProduct
        : companyStore.generatedTexts.salesText,
      language,
    });
  }

  async function generateRevision(
    recaptchaToken: string,
    signal: AbortSignal,
    modificators: SalesTextModificator[],
    language: Language
  ) {
    await checkActiveScan(false, recaptchaToken, language);
    // const api = 'revision';
    await companyStore.generateTextsAsStreams(
      'modification',
      recaptchaToken,
      signal,
      language,
      modificators
    );
    if (companyStore.revisionNew !== '') {
      await companyStore.createResultText({
        name: 'Salestext Revision',
        resultTextType: ResultTextType.REVISION,
        scanId: activeScan.value?.id ?? '',
        text: companyStore.revisionNew,
        modifications: modificators,
        language,
      });
      companyStore.revisionNew = '';
    }
  }

  async function generateWhatsWhy(
    isProductScan = false,
    recaptchaToken: string,
    signal: AbortSignal,
    language: Language
  ) {
    await checkActiveScan(isProductScan, recaptchaToken, language);
    const api = isProductScan ? 'what-why-product' : 'what-why';

    await companyStore.generateTextsAsStreams(
      api,
      recaptchaToken,
      signal,
      language
    );

    await companyStore.createResultText({
      name: `${baseCompany.value?.name} - ${targetCompany.value?.name}`,
      resultTextType: ResultTextType.WHATWHY,
      scanId: companyStore.activeScan?.id ?? '',
      text: isProductScan
        ? companyStore.generatedTexts.whatWhyProduct
        : companyStore.generatedTexts.whatWhy,
      language,
    });
  }

  async function checkCompatibilityLanding(
    recaptchaToken: string,
    language: Language
  ) {
    const res = await OpenAiService.checkCompatibility(
      currentScan.companyProfile as Profile,
      currentScan.targetProfile as Profile,
      recaptchaToken,
      language
    );
    if (!res?.isCompatible) {
      scanStore.setCompatibilityReason(res?.reason ?? '');
      return;
    }
    return res;
  }

  async function checkCompatibility(
    recaptchaToken: string,
    signal: AbortSignal,
    language: Language
  ) {
    const res = await $fetch<{ isCompatible: boolean; reason: string }>(
      '/api/check/compatibility',
      {
        body: {
          baseCompany: companyStore.baseCompany,
          targetCompany: companyStore.targetCompany,
          scanId: companyStore.activeScan?.id ?? '',
          language,
        },
        method: 'POST',
        headers: { 'X-Recaptcha-Token': recaptchaToken },
        retry: false,
        signal,
      }
    );

    return res;
  }

  return {
    checkCompatibility,
    checkCompatibilityLanding,
    clear,
    currentScan,
    emitter,
    generateRevision,
    generateSalesText,
    generateSalesTextLanding,
    generateWhatsWhy,
    generateWhatsWhyLanding,
    matchProfiles,
    setCompanyProfile,
    setCompanyUrl,
    setDetailedProduct,
    setTargetProfile,
    setTargetUrl,
  };
};
