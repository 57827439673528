import type { ScanProfiles } from '@/interfaces/scan-profiles.interface';
import type { Profile, ResultText, Scan } from '@prisma/client';
import type { Language } from '~/enum/language.enum';

export interface WrappedScanProfiles {
  [key: string]: ScanProfiles;
}

export const useScanStore = defineStore('scan', () => {
  const newScan = {
    resultTexts: {
      compatibilityReason: ref(''),
      salesArgumentation: ref(''),
      salesText: ref(''),
      salesTextProduct: ref(''),
      whatWhy: ref(''),
      whatWhyProduct: ref(''),
    },
  };

  const activeScan = ref<ScanProfiles>({
    resultTexts: {
      compatibilityReason: ref(''),
      salesArgumentation: ref(''),
      salesText: ref(''),
      salesTextProduct: ref(''),
      whatWhy: ref(''),
      whatWhyProduct: ref(''),
    },
  });

  const activeScans = ref<WrappedScanProfiles[]>([]);

  const resultTexts = ref({
    compatibilityReason: ref(''),
    salesArgumentation: '',
    salesText: ref(''),
    salesTextProduct: '',
    whatWhy: ref(''),
    whatWhyProduct: '',
  });

  async function setWhatsWhy(value: string) {
    activeScan.value.resultTexts.whatWhy = value;
  }

  async function setSalesText(value: string) {
    activeScan.value.resultTexts.salesText = value;
  }

  async function setCompatibilityReason(value: string) {
    activeScan.value.resultTexts.compatibilityReason = value;
  }

  async function setTarget(profile: Profile) {
    activeScan.value.targetProfile = profile;
  }
  async function setBase(profile: Profile) {
    activeScan.value.companyProfile = profile;
  }

  function setScan() {
    const scan = {
      id: { ...newScan },
    };
    activeScans.value.push(scan);
  }

  // TODO: looks unused? same function exists in company.store
  async function createScan(args: {
    companyProfileId?: string;
    detailedProductId?: string;
    targetProfileId: string;
    language: Language;
  }) {
    const { companyProfileId, targetProfileId, detailedProductId } = args;

    const scan = await $fetch<Scan>('/api/scans', {
      method: 'POST',
      body: {
        companyProfileId,
        targetProfileId,
        detailedProductId,
        language: args.language,
      },

      retry: false,
    });
    return scan;
  }

  async function createResultText(args: {
    name: string;
    resultTextType: 'SALESTEXT' | 'WHATWHY';
    scanId: string;
    text: string;
    language: Language;
  }) {
    const { name, resultTextType, scanId, text, language } = args;

    await $fetch<ResultText>('/api/resulttexts', {
      method: 'POST',
      body: {
        name,
        resultTextType,
        scanId,
        text,
        language,
      },
      retry: false,
    });
  }

  function clear() {
    // Reset newScan
    newScan.resultTexts.compatibilityReason.value = '';
    newScan.resultTexts.salesArgumentation.value = '';
    newScan.resultTexts.salesText.value = '';
    newScan.resultTexts.salesTextProduct.value = '';
    newScan.resultTexts.whatWhy.value = '';
    newScan.resultTexts.whatWhyProduct.value = '';

    // Reset activeScan
    activeScan.value.resultTexts.compatibilityReason = '';
    activeScan.value.resultTexts.salesArgumentation = '';
    activeScan.value.resultTexts.salesText = '';
    activeScan.value.resultTexts.salesTextProduct = '';
    activeScan.value.resultTexts.whatWhy = '';
    activeScan.value.resultTexts.whatWhyProduct = '';

    // Clear activeScans array
    activeScans.value = [];

    // Reset resultTexts
    resultTexts.value.compatibilityReason = '';
    resultTexts.value.salesArgumentation = '';
    resultTexts.value.salesText = '';
    resultTexts.value.salesTextProduct = '';
    resultTexts.value.whatWhy = '';
    resultTexts.value.whatWhyProduct = '';
  }

  return {
    activeScan,
    activeScans,
    clear,
    createResultText,
    createScan,
    resultTexts,
    setBase,
    setCompatibilityReason,
    setSalesText,
    setScan,
    setTarget,
    setWhatsWhy,
  };
});
