import type { Profile } from '@prisma/client';
import type { Language } from '~/enum/language.enum';

export const ProfileService = {
  async create(
    url: string,
    isTargetCompany: boolean,
    recaptchaToken: string,
    language: Language,
    signal?: AbortSignal
  ): Promise<Profile> {
    const res = await $fetch(
      `/api/scans/company-landing?url=${url}&isTargetCompany=${isTargetCompany}&language=${language}`,
      {
        signal,
        headers: {
          'X-Recaptcha-Token': recaptchaToken,
        },
        retry: false,
      }
    );

    return {
      ...res,
      createdAt: new Date(res.createdAt),
      updatedAt: new Date(res.updatedAt),
    };
  },
  async post(
    profile: Profile,
    isTargetCompany: boolean,
    language: Language
  ): Promise<Profile> {
    const res = await $fetch<Profile>(`/api/profiles`, {
      method: 'POST',
      body: JSON.stringify({
        ...profile,
        isTargetCompany,
        language,
      }),
      retry: false,
    });

    return {
      ...res,
      createdAt: new Date(res.createdAt),
      updatedAt: new Date(res.updatedAt),
    };
  },
};
